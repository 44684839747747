//
//
//
//
//

  import OrderList from "@/page/admin/myOrder/flightOrder/component/list/1.0.1/index.vue";
export default {
  data() {
    return {
      statusList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: 31,
          label: "改签中"
        },
        {
          value: 33,
          label: "待补款"
        },
        {
          value: 34,
          label: "待出票"
        },
        {
          value: 35,
          label: "改签成功"
        },
        {
          value: 36,
          label: "改签失败"
        },
        {
          value: 37,
          label: "取消改签"
        }
      ]
    };
  },
  components: {
    OrderList
  },
  methods: {},
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
